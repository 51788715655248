/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports ----------------------------------- */

/* Type declaration ------------------------------------ */

/* Internal variables ---------------------------------- */

/* Styled components -------------------------------------------------------- */
const LayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media ${(props) => props.theme.media.desktop} {
    flex-direction: column;
    gap: 0px;
  }

  @media ${(props) => props.theme.media.mobile.portrait} {
    justify-content: space-between;
  }
`

const CaseTitle = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: .95rem;
  padding: 0px;
  gap: 5px;

  div {
    font-size: .8rem;
  }
`

/* Component declaration ------------------------------- */
interface MainLayoutTitleStatusProps {
  caseId: string;
}

const MainLayoutTitleStatus: React.FC<MainLayoutTitleStatusProps> = ({ caseId }) => {

  return (
    <LayoutContainer>
      <CaseTitle>
        Mon Dossier Sinistre
        <div>
          {`N° ${caseId}`}
        </div>
      </CaseTitle>
    </LayoutContainer>
  )
}

export default MainLayoutTitleStatus
