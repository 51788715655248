/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useGetCaseHistoryListQuery,
  useGetCaseRatingQuery,
} from 'store/api'
import { useCaseId } from 'store/hooks'

/* Component imports ----------------------------------- */
import { CircularProgress } from '@mui/material'
import {
  CancelRounded,
  CheckCircleRounded,
  CircleRounded,
} from '@mui/icons-material'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import CaseFollowUpHistoryCard from './CaseFollowUpComponents/CaseFollowUpHistoryCard'

/* Type imports ---------------------------------------- */
import { WorkflowEtat } from 'API/__generated__/Api'

/* Types declaration ----------------------------------- */

/* Styled components -------------------------------------------------------- */
interface LineContainerProps {
  last: boolean;
}

const LineContainer = styled.div<LineContainerProps>`
  display: flex;
  align-items: center;
  border-left: 2px solid ${(props) => props.theme.colors.lightgrey};
  margin-left: 10px;
  padding-left: 20px;
  padding-bottom: ${(props) => props.last ? '0px' : '30px'};

  .MuiPaper-root {
    width: 100%;
  }
`

const Dot = styled.div`
  display: flex;
  align-items: center;
  margin-left: -33px;
  margin-right: 10px;
`

/* Component declaration ------------------------------- */
interface CaseFollowUpPageProps {}

const CaseFollowUpPage: React.FC<CaseFollowUpPageProps> = () => {
  const caseId = useCaseId()

  const {
    currentData: caseHistoryList = [],
    isFetching: isFetchingCaseHistoryList,
  } = useGetCaseHistoryListQuery(caseId)
  const {
    currentData: displayRateButton = true,
    isFetching: isFetchingDisplayRateButton,
  } = useGetCaseRatingQuery(caseId)

  return (
    <div>
      <LargeTitle>
        Suivi Dossier
      </LargeTitle>
      {(isFetchingCaseHistoryList || isFetchingDisplayRateButton) && <CircularProgress />}
      {
        caseHistoryList.map((caseHistory, index) => (
          <LineContainer
            key={`${caseHistory.libelle}-${index}`}
            last={index === caseHistoryList.length - 1}
            id={`${caseHistory.libelle}-${index}`}
          >
            <Dot>
              {
                caseHistory.etat === WorkflowEtat.EnAttente &&
                  <CircleRounded color="primary" />
              }
              {
                caseHistory.etat === WorkflowEtat.NonFait &&
                  <CircleRounded color="info" />
              }
              {
                caseHistory.etat === WorkflowEtat.Fait &&
                  <CheckCircleRounded color="primary" />
              }
              {
                caseHistory.etat === WorkflowEtat.Annule &&
                  <CancelRounded color="error" />
              }
            </Dot>
            <CaseFollowUpHistoryCard
              history={caseHistory}
              displayRateButton={!displayRateButton}
            />
          </LineContainer>
        ))
      }
      <Footer />
    </div>
  )
}

export default CaseFollowUpPage
