/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.palette.secondary.main};
  background-color:  ${(props) => props.theme.colors.lightgrey};
  margin-top: 60px;
  margin-bottom: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    margin-bottom: 0px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {

  return (
    <FooterContainer>
      {`Créé par IREN - © ${new Date().toLocaleDateString('fr-FR', { year: 'numeric' })}`}
    </FooterContainer>
  )
}

export default Footer
