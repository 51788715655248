/* Framework imports -------------------------------------------------------- */
import { useMemo } from 'react'
import * as Redux from 'react-redux'

/* Module imports ----------------------------------------------------------- */
import {
  persistor,
  store,
} from 'store/store'
import { getAuthFromLocalStorage } from 'helpers/localStorageHelpers'
import {
  setAuthInfo,
  selectAuthInfo,
  resetAuthInfo,
  selectCaseId,
} from './slices/authSlice'

/* Type imports ---------------------------------------- */
import type {
  RootState,
  AppDispatch,
} from 'store/store'
import type { LoginResponseDossier } from 'API/__generated__/Api'
import type { Nullable } from 'types/Nullable'

/* Generic Redux store hooks --------------------------- */
export const useAppDispatch: () => AppDispatch = Redux.useDispatch
export const useAppSelector: Redux.TypedUseSelectorHook<RootState> = Redux.useSelector

/* Specific Redux store hooks -------------------------- */
export const useAuthInfo = (): Nullable<LoginResponseDossier> | null => {
  const dispatch = useAppDispatch()

  const lLoginInfo: Nullable<LoginResponseDossier> = useAppSelector(
    selectAuthInfo,
    (pLeft: Nullable<LoginResponseDossier>, pRight: Nullable<LoginResponseDossier>): boolean => {
      return pLeft.referenceDossier?.refComplete === pRight.referenceDossier?.refComplete &&
        pLeft.token === pRight.token
    },
  )

  return useMemo<Nullable<LoginResponseDossier> | null>((): Nullable<LoginResponseDossier> | null => {
    if (lLoginInfo.token === null) {
      const lLocalStorageAuthInfo = getAuthFromLocalStorage()

      if (lLocalStorageAuthInfo !== null) {
        dispatch(setAuthInfo(lLocalStorageAuthInfo))
        return lLocalStorageAuthInfo
      }

      return null
    }

    return lLoginInfo
  }, [ lLoginInfo, dispatch ])
}

export const useCaseId = (): string => {
  const dispatch = useAppDispatch()

  const caseId: string = useAppSelector(selectCaseId)

  return useMemo<string>((): string => caseId, [ caseId, dispatch ])
}

export const onLogoutReset = () => {
  persistor.pause()
  persistor.flush().then(() => {return persistor.purge()}).catch(console.error)
  store.dispatch(resetAuthInfo())
}
