/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */

/* Components imports ---------------------------------- */
import { ReactComponent as Logo } from 'assets/iRENLogo.svg'

/* Styled components -------------------------------------------------------- */

/* Component declaration ------------------------------- */
interface GroupLogoProps {}

const GroupLogo: React.FC<GroupLogoProps> = () => {

  return (
    <Logo className="loader-logo" />
  )
}

export default GroupLogo
