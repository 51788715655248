/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Component imports ----------------------------------- */
import {
  Card,
  IconButton,
} from '@mui/material'
import { ReplyOutlined } from '@mui/icons-material'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'

/* Type imports ---------------------------------------- */
import type { Message } from 'API/__generated__/Api'

/* Types declaration ----------------------------------- */

/* Styled components -------------------------------------------------------- */
interface CardMessageContainerProps {
  right: string;
  unread?: string;
  readable?: string;
}

const MessageAndAnswerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const CardMessageContainer = styled(Card)<CardMessageContainerProps>`
  margin-left: ${(props) => props.right === 'true' ? 'auto' : '0px'};
  min-width: 55%;
  text-align: ${(props) => props.right === 'true' ? 'right' : 'left'};
  border: ${(props) => props.unread === 'true' ? `1px solid ${props.theme.palette.primary.main} !important` : 'initial'};
  cursor: ${(props) => props.readable === 'true' ? 'pointer' : 'inital'};

  h5 {
    margin: 0px;
    font-size: 1rem;
  }

  p {
    margin: 5px 0px -7px;
    font-size: 1rem;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;

    h5 {
      margin: 0px;
      font-size: .9rem;
    }

    p {
      margin: 3px 0px -7px;
      font-size: .9rem;
    }
  }
`
const CardContentContainer = styled.div`
  padding: 15px;

  @media ${(props) => props.theme.media.mobile.main} {
    padding: 10px !important;
  }
`

const MessageHeader = styled.div<CardMessageContainerProps>`
  display: flex;
  flex-direction: ${(props) => props.right === 'true' ? 'row-reverse' : 'row'};
  justify-content: space-between;
  align-items: center;
  font-size: .95rem;
  color: ${(props) => props.theme.palette.primary.main};

  @media ${(props) => props.theme.media.mobile.main} {
    align-items: ${(props) => props.right === 'true' ? 'end' : 'start'};
    flex-direction: column-reverse;
  }
`

const DateContainer = styled.div<CardMessageContainerProps>`
  font-size: .85rem;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: ${(props) => props.right === 'true' ? '0px' : '10px'};
  margin-right: ${(props) => props.right === 'true' ? '10px' : '0px'};
  text-align: ${(props) => props.right === 'true' ? 'left' : 'right'};

  @media ${(props) => props.theme.media.mobile.main} {
    align-items: start;
    font-size: .8rem;
    margin-left: 0;
    margin-right: 0;

    #new-message {
      font-size: .7rem;
    }
  }

  :last-child {
    color: black;
  }
`

const ReplyIcon = styled(ReplyOutlined)`
  transform: rotateY(180deg);
`

const MessageContainer = styled.p`
  white-space: pre-line;
`

/* Component declaration ------------------------------- */
interface MessagesCardProps {
  myId: string;
  message: Message;
  onReply: () => void;
}

const MessagesCard: React.FC<MessagesCardProps> = ({
  myId,
  message,
  onReply,
}) => {
  const isMe = (id: string) => id === myId

  return (
    <MessageAndAnswerContainer>
      <CardMessageContainer
        right={isMe(message.emetteur.id) ? 'true' : 'false'}
        unread={isMe(message.destinataire.id) && !message.lu ? 'true' : 'false'}
        readable={isMe(message.destinataire.id) ? 'true' : 'false'}
      >
        <CardContentContainer>
          <MessageHeader right={isMe(message.emetteur.id) ? 'true' : 'false'}>
            <b>
              {isMe(message.emetteur.id) ? 'Vous' : message.emetteur.nom}
              {' à '}
              {isMe(message.destinataire.id) ? 'Vous' : message.destinataire.nom}
            </b>
            <DateContainer right={isMe(message.emetteur.id) ? 'true' : 'false'}>
              {
                isMe(message.destinataire.id) && !message.lu && (
                  <ColoredSquareChip
                    color="primary"
                    textColor="white"
                    smaller
                    id="new-message"
                  >
                    Nouveau
                  </ColoredSquareChip>
                )
              }
              {DateUtils.APIStrToLocalDateString(message.date, { hour: '2-digit', minute: '2-digit' })}
            </DateContainer>
          </MessageHeader>
          <h5>
            {message.objet}
          </h5>
          <MessageContainer>
            {message.commentaire}
          </MessageContainer>
        </CardContentContainer>
      </CardMessageContainer>
      {
        !isMe(message.emetteur.id) &&
          <IconButton
            color="primary"
            onClick={onReply}
          >
            <ReplyIcon />
          </IconButton>
      }
    </MessageAndAnswerContainer>
  )
}

export default MessagesCard
