/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */

/* Component imports ----------------------------------- */
import {
  Field,
  useField,
} from 'formik'
import { Autocomplete } from 'formik-mui'
import { TextField } from '@mui/material'

/* Type imports ---------------------------------------- */
import type { FieldAttributes } from 'formik'
import type { AutocompleteRenderInputParams } from '@mui/material'
import type { CodeLabel } from 'API/__generated__/Api'

/* Component declaration ------------------------------- */
interface AutocompleteFieldProps<T> {
  name: string;
  options: T[];
  placeholder?: string;
  size?: string;
  getOptionLabel?: (option: T) => string;
  isOptionEqualToValue?: (option: T, value: T) => boolean;
}

const AutocompleteField: React.FC<FieldAttributes<AutocompleteFieldProps<unknown>>> = ({
  name,
  placeholder = 'Sélectionnez',
  options,
  getOptionLabel,
  isOptionEqualToValue,
  ...props
}) => {
  const [ , meta ] = useField(name)

  const defaultIsOptionEqualToValue = (option: CodeLabel, value: CodeLabel): boolean => option.code === value.code || value.code === ''

  const defaultGetOptionLabel = (option: CodeLabel): string => option?.libelle || ''

  return (
    <Field
      component={Autocomplete}
      name={name}
      options={options}
      getOptionLabel={getOptionLabel ?? defaultGetOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue ?? defaultIsOptionEqualToValue}
      renderInput={
        (params: AutocompleteRenderInputParams): React.ReactElement => (
          <TextField
            {...params}
            name={name}
            error={meta.touched && meta.error !== undefined}
            helperText={meta.touched && meta.error?.toString()}
            placeholder={placeholder}
            variant="outlined"
          />
        )
      }
      {...props}
    />
  )
}

export default AutocompleteField
