/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import { getAddress } from 'helpers/getAddress'
import {
  useIsDesktop,
  useIsMobile,
  useIsTabletPortrait,
} from 'helpers/hooks/useIsMedia'

/* Component imports -------------------------------------------------------- */
import { Collapse } from '@mui/material'
import VignetteRendererWithFilename from 'components/MediaRenderer/VignetteRendererWithFilename'
import DropdownArrow from 'components/DropdownArrow/DropdownArrow'

/* Type declaration --------------------------------------------------------- */
import type {
  Document,
  Dossier,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

interface BlueTitleProps {
  isDropdown?: boolean;
}

const BlueTitle = styled.div<BlueTitleProps>`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  margin: 3px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${(props) => props.theme.media.desktop} {
    cursor: ${(props) => props.isDropdown ? 'pointer' : 'initial'};
  }
`

const BoldSeparator = styled.div`
  width: 200% !important;
  margin: 15px -15px 5px -15px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const GridContainer = styled.div`
  display: grid;
  gap: 20px;
  align-items: stretch;
  justify-content: stretch;

  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
`

/* Component declaration ---------------------------------------------------- */
interface MainLayoutSummaryProps {
  summary: Dossier;
  documents: Document[];
}

const MainLayoutSummary: React.FC<MainLayoutSummaryProps> = ({ summary, documents }) => {
  const isDesktop = useIsDesktop()
  const isMobile = useIsMobile()
  const isTablet = useIsTabletPortrait()
  const [ expanded, setExpanded ] = useState<boolean>(false)

  const handleExpandClick = (): void => {
    setExpanded(!expanded)
  }

  return (
    <SummaryContainer>
      <BlueTitle>
        Informations Sinistre
      </BlueTitle>
      <div>
        {'Nature : '}
        <b>
          {summary.natureSinistre?.libelle}
        </b>
      </div>
      <div>
        {'Date : '}
        <b>
          {DateUtils.APIStrToLocalDateString(summary.dateSinistre)}
        </b>
      </div>
      <div>
        {'Lieu : '}
        <b>
          {getAddress(summary.adresseSinistre)}
        </b>
      </div>
      <BoldSeparator />
      <BlueTitle>
        Mes informations
      </BlueTitle>
      <div>
        {'Nom : '}
        <b>
          {[ summary.assure?.nom, summary.assure?.prenom ].join(' ')}
        </b>
      </div>
      <div>
        {'Adresse : '}
        <b>
          {getAddress(summary.assure?.adresse)}
        </b>
      </div>
      <div>
        {'Téléphone portable : '}
        <b>
          {summary.assure?.telephonePortable}
        </b>
      </div>
      <div>
        {'Téléphone fixe : '}
        <b>
          {summary.assure?.telephoneFixe}
        </b>
      </div>
      <div>
        {'Email : '}
        <b>
          {summary.assure?.email}
        </b>
      </div>
      <BoldSeparator />
      <BlueTitle>
        Assurance
      </BlueTitle>
      <div>
        {'Compagnie : '}
        <b>
          {summary.compagnieAssurance?.nom}
        </b>
      </div>
      <div>
        {'N° de contrat : '}
        <b>
          {summary.numeroContrat}
        </b>
      </div>
      <div>
        {'Réf sinistre : '}
        <b>
          {summary.referenceCompagnie}
        </b>
      </div>
      <BoldSeparator />
      <BlueTitle
        onClick={handleExpandClick}
        isDropdown={isDesktop && documents.length > 0}
      >
        {`Mes Documents ${isDesktop ? (`(${documents.length} disponible${documents.length > 1 ? 's' : ''})`) : ''}`}
        {
          isDesktop && documents.length > 0 &&
            <DropdownArrow expanded={expanded.toString()} />
        }
      </BlueTitle>
      <Collapse
        in={isMobile || isTablet ? true : expanded}
        timeout="auto"
        unmountOnExit
      >
        <GridContainer>
          {
            documents.map((document, index) => (
              <VignetteRendererWithFilename
                key={`${document.libelle}-${index}`}
                document={{ ...document, fileName: document.libelle }}
                onClick={() => window.open(document.url || '', '_blank', 'noreferrer')}
                width="min(100px, 100%)"
                height="120px"
              />
            ))
          }
        </GridContainer>
      </Collapse>
    </SummaryContainer>
  )
}

export default MainLayoutSummary
