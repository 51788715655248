/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { isValidString } from 'helpers/isValidString'

/* Component imports ----------------------------------- */
import PlaceholderImage from './PlaceholderImage'

/* Type imports ---------------------------------------- */
import type { PieceJointe } from 'API/__generated__/Api'

/* Internal variables ---------------------------------- */

/* Styled components -------------------------------------------------------- */
interface ImageSizeProps {
  width: string;
  height: string;
}

const Image = styled.img<ImageSizeProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 3px;
  object-fit: cover;
  object-position: 50% 0;
`

/* Component declaration ------------------------------- */
interface VignetteRendererProps {
  document?: PieceJointe;
  file?: File;
  width: string;
  height: string;
}

const VignetteRenderer: React.FC<VignetteRendererProps> = ({
  document,
  file,
  width,
  height,
}) => {
  const [ isValidImage, setIsValidImage ] = useState<boolean>(false)

  useEffect(() => {
    if (file?.type.split('/')[0] === 'image') {
      setIsValidImage(true)
    }
    if (isValidString(document?.urlVignette)) {
      setIsValidImage(true)
    }
  }, [ file, document ])

  const onImageError = ({ currentTarget }: React.SyntheticEvent<HTMLImageElement, Event>): void => {
    currentTarget.onerror = null // prevents looping
    setIsValidImage(false)
  }

  if (isValidImage) {
    return (
      <Image
        src={file ? URL.createObjectURL(file) : document?.urlVignette || ''}
        alt={document?.fileName ?? file?.name}
        width={width}
        height={height}
        onError={onImageError}
        className="image-container"
      />
    )
  }

  return (
    <PlaceholderImage
      width={width}
      height={height}
    />
  )
}

export default VignetteRenderer
