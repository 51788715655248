/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'
import { setAuthInfo } from 'store/slices/authSlice'
import { useAuthenticateMutation } from 'store/api'
import { isValidString } from 'helpers/isValidString'
import { isApiResponse } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import RouteTitle from 'router/RouteTitle'
import BaseLayout from 'layouts/BaseLayout/BaseLayout'
import AuthContainer from 'components/AuthComponents/AuthContainer'
import AuthWelcomeMessage from 'components/AuthComponents/AuthWelcomeMessage'
import AuthErrorMessage from 'components/AuthComponents/AuthErrorMessage'

/* Type imports ------------------------------------------------------------- */
import type { LoginResponseDossier } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const Message = styled.div`
  margin-top: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    margin-top: 10px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface ExternalLoginPageProps {}

const ExternalLoginPage: React.FC<ExternalLoginPageProps> = () => {
  const { caseRef } = useParams<{ caseRef: string }>()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(true)
  const [ errorMessage, setErrorMessage ] = useState<string | null>(null)
  const [ welcomeMessage, setWelcomeMessage ] = useState<boolean>(true)

  const [
    tryLogin,
  ] = useAuthenticateMutation()

  const onLogin = (refExtranet?: string) => {
    setIsSubmitting(true)
    if (isValidString(refExtranet) && refExtranet !== 'null') {
      setWelcomeMessage(true)
      tryLogin({ refExtranet })
        .then((pResult) => {
          setIsSubmitting(false)

          if (isApiResponse<LoginResponseDossier>(pResult)) {
            dispatch(setAuthInfo((pResult.data)))
            navigate('/')
          } else {
            setErrorMessage('Erreur lors de la connexion.')
          }
        })
        .catch((error) => {
          setIsSubmitting(false)
          setErrorMessage('Erreur lors de la connexion.')
          console.error(error)
        })
    } else {
      if (refExtranet === 'null') {
        setWelcomeMessage(false)
      }
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    onLogin(caseRef)
  }, [ caseRef ])

  return (
    <BaseLayout className="AuthLayout">
      <RouteTitle title="Connexion externe" />
      <AuthContainer>
        <AuthWelcomeMessage>
          {welcomeMessage ? `Bienvenue sur ` : `Merci de votre visite sur `}
          <b>
            l'Extranet Assuré iREN
          </b>
        </AuthWelcomeMessage>
        {
          isSubmitting &&
            <Message>
              Veuillez patienter pendant que nous vous connectons automatiquement...
              <CircularProgress />
            </Message>
        }
        {
          isValidString(errorMessage) &&
            <AuthErrorMessage>
              {errorMessage}
            </AuthErrorMessage>
        }
      </AuthContainer>
    </BaseLayout>
  )
}

export default ExternalLoginPage
